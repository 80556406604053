/* eslint-disable no-console,no-undef, camelcase */
/* eslint-disable react/no-unknown-property */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  fetchOnDemandVideosByCatgoryId,
} from "../../../redux/actions";
import {
  OndemandContainer,
  HeadingContainer,
  EventHeading,
} from "./style";
import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull";
import Waiting from "../../Waiting";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ToolTipNew } from "../../AdminTeamCreation/styles";
import StyledDropDown from "./categoryDropdown";
import { StyledSvgNew } from "../styles";
import { FetchRecommendedEventsByCategory, FetchMostWatchedEventVideos } from "../../../redux/constants/apiConstants";
import { fetchApi } from "../../../utils/methods";
import { toast } from "react-toastify";
import RecommendedEvents from "../../CompanyEvents/RecommendedEvents";
import EventSlider from '../RecommendedSlider';

class OnDemandDetailsMain extends Component {
  constructor() {
    super();
    this.state = {
      pageNumber: 1,
      tab: 1,
      activeStatus: true,
      onHover: -1,
      eventCreate: false,
      showMenu: false,
      dropdown: 0,
      open: false,
      selectedItem: "",
      showBack: false,
      recommendedEventData:[],
      featuredEventData:null,
      currentSlide:1,
    };
  }

  componentDidMount() {
    const {
      fetchOnDemandVideosByCatgoryId,
      history,
      ondemandCategory,
    } = this.props;
    this.getFeaturedEvents();
    const StringArray = history.location.pathname.split("/");
    fetchOnDemandVideosByCatgoryId(StringArray[StringArray.length - 1]);
    this.getRecommendedEvents(StringArray[StringArray.length - 1]);
    ondemandCategory &&
      ondemandCategory.map((item) => {
        if (item.id == StringArray[StringArray.length - 1]) {
          this.setState({
            selectedItem: item.name,
          });
        }
      });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.history.location !== this.props.history.location) {
      const { history, fetchOnDemandVideosByCatgoryId, ondemandCategory } =
        this.props;
      const StringArray = history.location.pathname.split("/");
      fetchOnDemandVideosByCatgoryId(StringArray[StringArray.length - 1]);
      ondemandCategory &&
        ondemandCategory.map((item) => {
          if (item.id == StringArray[StringArray.length - 1]) {
            this.setState({
              selectedItem: item.name,
            });
          }
        });
    }
  }

  getFeaturedEvents = async () => {
    const apiUrl = FetchMostWatchedEventVideos ;
    try {
      const res = await fetchApi(apiUrl, "GET");
      if (res.status === "success") {
        this.setState({
          featuredEventData: res?.data
        })
      } else {
        toast.error("Api Failed");
        this.setState({
          featuredEventData: []
        })
      }
    } catch (error) {
      toast.error("Api Failed");
    }
  };

  getRecommendedEvents = async (categoryId) => {
    const apiUrl = FetchRecommendedEventsByCategory + (categoryId && "/" + categoryId);
    try {
      const res = await fetchApi(apiUrl, "GET");
      if (res.status === "success") {
        this.setState({
          recommendedEventData: res?.data
        })
      } else {
        toast.error("Api Failed");
      }
    } catch (error) {
      toast.error("Api Failed");
    }
  };

  selectCategoryAPI = (value) => {
    const { fetchOnDemandVideosByCatgoryId } = this.props;
    fetchOnDemandVideosByCatgoryId(value);
    this.getRecommendedEvents(value);
  };

  showArticle = (id) => {
    const { history, location } = this.props;
    const currentLocation = location.pathname;
    const stringArray = location.pathname.split("/");
    let url = stringArray[2] === "videos-library" ? "fitness" : "article";
    history.push(`${currentLocation}/${url}/${id}`);
  };

  onScroll = (e, events) => {
    const { pageNumber } = this.state;
    const { loadMoreEvents, totalCount } = this.props;
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (events.length < totalCount) {
      if (bottom) {
        this.setState(
          {
            pageNumber: pageNumber + 1,
          },
          () => {
            loadMoreEvents(this.state.pageNumber);
          }
        );
      }
    }
  };

  onEnterMouse = (id) => {
    this.setState({
      onHover: id,
    });
  };

  updateCategoryName = (value) => {
    this.setState({
      selectedItem: value,
    });
  };

  onLeaveMouse = () => {
    this.setState({
      onHover: -1,
    });
  };

  showPastEvents = (status) => {
    this.setState({ activeStatus: status ? 0 : 1 });
  };

  eventButtonStatusHover = (value) => {
    if (value === "event")
      this.setState({
        eventCreate: true,
      });
    else if (value === "menu") {
      this.setState({
        showMenu: true,
      });
    } else {
      this.setState({
        showBack: true,
      });
    }
  };

  eventButtonStatus = (value) => {
    if (value === "event")
      this.setState({
        eventCreate: false,
      });
    else if (value === "menu") {
      this.setState({
        showMenu: false,
      });
    } else {
      this.setState({
        showBack: false,
      });
    }
  };

  showDropDown = () => {
    this.setState({
      dropdown: !this.state.dropdown,
      open: true,
    });
  };

  hideDropDown = () => {
    this.setState({
      dropdown: !this.state.dropdown,
    });
  };

  onSelect = (value) => {
    this.setState({
      selectedItem: value,
    });
  };

  renderHeading = () => {
    const { eventCreate, showMenu, dropdown, selectedItem, showBack } =
      this.state;
    const { role, t } = this.props;
    const popover = (value) => (
      <Popover
        id="popover-basic"
        style={{
          width: "auto",
          padding: "9px 14px",
          background: "#FFFFFF",
          border: "1px solid #005c87",
          boxShadow: "0px 13px 28px rgba(0, 0, 0, 0.3)",
          borderRadius: "6px",
        }}
      >
        <div
          style={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#005c87",
            textAlign: "center",
          }}
        >
          {value}
        </div>
      </Popover>
    );

    return (
      <EventHeading style={{height:"60px"}}>
        <ToolTipNew
          margin={"auto 0px auto 15px"}
          active={showBack}
          onMouseEnter={() => this.eventButtonStatusHover("back")}
          onMouseLeave={() => this.eventButtonStatus("back")}
          height="40px"
          width="40px"
          padding
        >
          <OverlayTrigger placement="bottom" overlay={popover(this.props.t("Back"))}>
            <div
              className='="toolTip'
              onClick={() => this.props.history.push(`/events`)}
            >
              <StyledSvgNew active={showBack} width="20px" height="25px">
                <svg
                  width="20px"
                  height="auto"
                  viewBox="-5 0 25 25"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                >
                  <g
                    id="icons"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="ui-gambling-website-lined-icnos-casinoshunter"
                      transform="translate(-1913.000000, -158.000000)"
                      fill="#005c87"
                      fillRule="nonzero"
                    >
                      <g id="1" transform="translate(1350.000000, 120.000000)">
                        <path
                          d="M566.453517,38.569249 L577.302459,48.9938158 L577.39261,49.0748802 C577.75534,49.423454 577.968159,49.8870461 578,50.4382227 L577.998135,50.6228229 C577.968159,51.1129539 577.75534,51.576546 577.333675,51.9774469 L577.339095,51.9689832 L566.453517,62.430751 C565.663694,63.1897497 564.399001,63.1897497 563.609178,62.430751 C562.796941,61.650213 562.796941,60.3675924 563.609432,59.5868106 L573.012324,50.5572471 L563.609178,41.4129456 C562.796941,40.6324076 562.796941,39.349787 563.609178,38.569249 C564.399001,37.8102503 565.663694,37.8102503 566.453517,38.569249 Z"
                          id="left"
                          transform="translate(570.500000, 50.500000) scale(-1, 1) translate(-570.500000, -50.500000) "
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </StyledSvgNew>
            </div>
          </OverlayTrigger>
        </ToolTipNew>

        <div className="title" style={{color:"#005c87", fontSize:'16px'}}>{`${t(selectedItem)} `}</div>
        {role === "ADMIN" && (
          <ToolTipNew
            margin={"auto 0px auto auto"}
            active={eventCreate}
            onMouseEnter={() => this.eventButtonStatusHover("event")}
            onMouseLeave={() => this.eventButtonStatus("event")}
          >
            <OverlayTrigger
              placement="bottom"
              overlay={popover(this.props.t("Create on-Demand Classes"))}
            >
              <div
                className='="toolTip'
                onClick={() =>
                  this.props.history.push(`/company/events/create-ondemand`)
                }
              >
                <StyledSvgNew active={eventCreate} width="24px" height="24px">
                  <svg
                    width={24}
                    height={22}
                    viewBox="0 0 24 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_14031_60351"
                      style={{
                        maskType: "alpha",
                      }}
                      maskUnits="userSpaceOnUse"
                      x={0}
                      y={0}
                      width={24}
                      height={24}
                    >
                      <rect width={24} height={24} fill="red" />
                    </mask>
                    <g mask="url(#mask0_14031_60351)">
                      <path
                        d="M23.0746 4.24921C22.5001 3.88846 21.7814 3.85559 21.177 4.16232L17.3023 6.11758V4.10266C17.3018 3.2799 16.9781 2.49118 16.4027 1.90945C15.8271 1.32773 15.0465 1.00058 14.2327 1H3.06963C2.25577 1.00057 1.47521 1.3277 0.899639 1.90945C0.324262 2.49118 0.000574085 3.27995 0 4.10266V19.8973C0.000560642 20.7201 0.324229 21.5088 0.899639 22.0905C1.47521 22.6723 2.25582 22.9994 3.06963 23H14.2327C15.0466 22.9994 15.8271 22.6723 16.4027 22.0905C16.9781 21.5088 17.3018 20.7201 17.3023 19.8973V17.8824L21.1737 19.8377C21.7791 20.1433 22.4979 20.1104 23.0737 19.7508C23.6494 19.3912 23.9998 18.756 24 18.0719V5.92776C24.0019 5.24348 23.6513 4.6075 23.0746 4.24883V4.24921ZM12 12.8462H9.48838V15.3847C9.48838 15.6871 9.32879 15.9664 9.06977 16.1175C8.81076 16.2686 8.49157 16.2686 8.23257 16.1175C7.97356 15.9664 7.81396 15.6871 7.81396 15.3847V12.8462H5.30234C5.00315 12.8462 4.72677 12.6849 4.57727 12.4231C4.42777 12.1613 4.42777 11.8387 4.57727 11.577C4.72677 11.3152 5.00316 11.1539 5.30234 11.1539H7.81396V8.6154C7.81396 8.31301 7.97355 8.03367 8.23257 7.88257C8.49158 7.73147 8.81076 7.73147 9.06977 7.88257C9.32878 8.03367 9.48838 8.31302 9.48838 8.6154V11.1539H12C12.2992 11.1539 12.5756 11.3152 12.7251 11.577C12.8746 11.8387 12.8746 12.1613 12.7251 12.4231C12.5756 12.6849 12.2992 12.8462 12 12.8462Z"
                        fill="#9c9c9c"
                      />
                    </g>
                  </svg>
                </StyledSvgNew>
              </div>
            </OverlayTrigger>
          </ToolTipNew>
        )}
        <ToolTipNew
          margin={role === "ADMIN" ? "auto 0px auto 0" : "auto 0px auto auto"}
          active={showMenu || dropdown != 0}
          onClick={() => this.showDropDown()}
          onMouseEnter={() => this.eventButtonStatusHover("menu")}
          onMouseLeave={() => this.eventButtonStatus("menu")}
        >
          <OverlayTrigger placement="bottom" overlay={popover(this.props.t("Select Categories"))}>
            <div onClick={() => this.showDropDown()}>
              <StyledSvgNew
                width="24px"
                height="24px"
                active={showMenu || dropdown != 0}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M1.42857 3C0.6396 3 0 3.63957 0 4.42857C0 5.21757 0.6396 5.85714 1.42857 5.85714H22.5714C23.3604 5.85714 24 5.21757 24 4.42857C24 3.63957 23.3604 3 22.5714 3H1.42857ZM1.42857 10.7143C0.6396 10.7143 0 11.3538 0 12.1429C0 12.9319 0.6396 13.5714 1.42857 13.5714H22.5714C23.3604 13.5714 24 12.9319 24 12.1429C24 11.3538 23.3604 10.7143 22.5714 10.7143H1.42857ZM1.42857 18.4286C0.6396 18.4286 0 19.0681 0 19.8571C0 20.6461 0.6396 21.2857 1.42857 21.2857H22.5714C23.3604 21.2857 24 20.6461 24 19.8571C24 19.0681 23.3604 18.4286 22.5714 18.4286H1.42857Z" fill="#007AB1"/>
                </svg>
              </StyledSvgNew>
            </div>
          </OverlayTrigger>
        </ToolTipNew>
      </EventHeading>
    );
  };

  handleBeforeChange = (oldIndex, newIndex) => {
    // Update the current slide index when the slider changes
    this.setState({ currentSlide: newIndex + 1 });
  };

  render() {
    const { history, t, link, ondemandByIdData } = this.props;
    const { activeStatus, featuredEventData, recommendedEventData, selectedItem } = this.state;
    if (
      isUndefined(ondemandByIdData) ||
      isNull(ondemandByIdData)||
      isNull(featuredEventData)
    ) {
      return <Waiting />;
    }
    return (
      <div>
        {activeStatus ? (
          <OndemandContainer>
            <div>
              <HeadingContainer>{this.renderHeading()}</HeadingContainer>
              {this.state.dropdown != 0 ? (
                <StyledDropDown
                  title={t("Adventures Categories")}
                  MenuItems={this.props.ondemandCategory}
                  isAdventurePage={true}
                  history={history}
                  onSelect={this.selectCategoryAPI}
                  isOpen={true}
                  hideDropDown={this.hideDropDown}
                  updateCategoryName={this.updateCategoryName}
                />
              ) : (
                ""
              )}
              {featuredEventData?.length > 0 ? (
                <RecommendedEvents
                  RecommendedEventData={featuredEventData}
                  margin="20px"
                  isActivity="1"
                  history={history}
                  RecommendedEventDataLoading={false}
                  heading={`Featured Events`}
                  marginB={"40px"}
                  buttonName={"Launch Event"}
                  isCoachEvent={1}
                />
              ) : null}
              {ondemandByIdData.length>0?
                <EventSlider
                  eventData={ondemandByIdData}
                  link={link}
                  history={history}
                  heading={`${selectedItem} Events`}
                />:null}
              {recommendedEventData.length>0?
                <EventSlider
                  eventData={recommendedEventData}
                  link={link}
                  history={history}
                  heading={"Recommended Events for you"}
                />:null}
            </div>
          </OndemandContainer>
        ) : (
          ""
        )}
      </div>
    );
  }
}

OnDemandDetailsMain.propTypes = {
  history: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  eventResults: PropTypes.array.isRequired,
  loadMoreEvents: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  attendEvent: PropTypes.func.isRequired,
  activeEvents: PropTypes.array.isRequired,
  pastEvents: PropTypes.array.isRequired,
  featuredVideos: PropTypes.array,
  t: PropTypes.func,
  link: PropTypes.string,
  location: PropTypes.object.isRequired,
  webinarCategory: PropTypes.array,
  onEnterMouse: PropTypes.func.isRequired,
  onLeaveMouse: PropTypes.func.isRequired,
  themesHeader: PropTypes.array,
  role: PropTypes.string,
  ondemandCategory: PropTypes.array,
  fetchOnDemandVideosByCatgoryId: PropTypes.func,
  ondemandByIdData: PropTypes.array
};

const mapStateToProps = (state) => ({
  ondemandCategory: state.events.ondemandCategory,
  ondemandByIdData: state.events.ondemandByIdData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchOnDemandVideosByCatgoryId: (id) =>dispatch(fetchOnDemandVideosByCatgoryId(id)),
});

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(OnDemandDetailsMain));