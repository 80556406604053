import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Thumbnail from '../../Thumbnail';
import { ImageUrl } from '../../../utils/constants';
import {  NewChampionsCard, NewVideoPlayer, EventCard, NewContentV2, TickV2, CircleV2, CircleContainerV2 } from '../RecommendEventVideos/styles';
import { withTranslation } from 'react-i18next';

class OnDemandCards extends Component {
  constructor() {
    super();
    this.state = {
      showVideoModal: false
    }
  }

  videoModal =() => {
    const { history, video } = this.props;
    history.push(`/events/on-demand/${video.category_name}/${video.id}`)
  }

  render() {
    const { video, grid, onHover, onEnterMouse, onLeaveMouse, length, t, width } = this.props;
    const cardLength = length + 1;
    return (
      <NewChampionsCard grid={grid} width={width} margin={(cardLength) % 3 == 0 ? "0px" : "25px"}>
        <EventCard videoCSS={grid} onMouseEnter={() => onEnterMouse(video.id)} onMouseLeave={() => onLeaveMouse()}>
          <NewVideoPlayer>
            <Thumbnail
              src={`${ImageUrl}/${video.thumbnail_image}`}
              alt={video.title}
            />
          </NewVideoPlayer>
          {onHover === video.id &&
            <div className='educateImageBackground' onClick={() => this.videoModal()}>
              <div className="getStarted" style={{color:"white",background:'#007AB1',fontSize:"18px"}}>
                <img src={ImageUrl+"/event-section/rocket.svg"}/>
                {t("Launch Event")}
              </div>
            </div>
          }
          <NewContentV2 newPadding={'newPadding'}>
            <div className='firstContainer'>
              <div className='name' style={{color:"#005c87"}}>
                {t(video.title)}
              </div>
              <div className='categoryName' style={{color:"#005C87"}}>{t(video.name)}</div>
            </div>
            <CircleContainerV2 marginRight={1}>
              {video.view_status?<img src={ImageUrl+"/event-section/read-arrow.png"} className='icon'/>:
                <CircleV2 checked={video.view_status} style={{border:video.view_status ? "3px solid #96C26E":"1px solid #669db7"}}>
                  <TickV2 checked={video.view_status} checkedValue={video.view_status} style={{top:'2.5px'}}/>
                </CircleV2>}
              <div className="quiz">
                {t("Watched")}
              </div>
            </CircleContainerV2>
          </NewContentV2>
        </EventCard>
      </NewChampionsCard>
    );
  }
}
OnDemandCards.propTypes = {
  video: PropTypes.object,
  grid: PropTypes.number,
  onHover: PropTypes.number,
  onEnterMouse: PropTypes.func.isRequired,
  onLeaveMouse: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  length: PropTypes.number,
  t: PropTypes.func,
  width: PropTypes.array
};

export default (withTranslation()(OnDemandCards));
