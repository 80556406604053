import styled,{css} from 'styled-components';

const OndemandContainer = styled.div`
  width: 100%;
  padding: 0 25px;
>div{
  width: 100%;
  max-width: 1246px;
  margin: auto;
  padding-top: 25px;
}
`;

const HeadingContainer = styled.div`
width: 100%;
width: ${({ width }) => width ? width : '100%'};
display: flex;
margin-bottom:25px;
margin-bottom:${({ margin }) => margin ? margin : '25px'};
>div:first-child{
justify-content: flex-start;
width: 100%;

`;

const SeeAllButton = styled.div`

display: flex;
justify-content: flex-end;

font-family:Rubik;
font-weight:400;
font-size :16px;
line-height :18px;
color:#FD7175
`;

const NewCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const NewTitle = styled.div`
  font-size: 18px;
  letter-spacing: 0px;
  color: #0D4270;
  display: inline-block;
  padding: 0px};
  text-transform: capitalize;
  margin-bottom: 15px;
  font-family: Rubik-Medium;
`;

const HeadingMain = styled.div`
  width: 100%;
  background: linear-gradient(rgb(82, 168, 237) 0%, rgb(138, 211, 247) 100%);
  display: flex;
  text-transform: capitalize;
  font-family: Rubik-Medium;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: white;
  padding: 13px;
  padding-left: 15px;
  border-radius: 7px;
`;

const EventHeading = styled.div`
  width: 100%;
  background: white;
  display: flex;
  height:70px;
  border-radius:6px;
  margin-bottom:${({ margin }) => margin ? margin : '0px'};
  .title{
    font-family: Rubik-Medium;
    font-size: 18px;
    line-height: 24px;
    color:#0D4270;
    padding-left:15px;
    display: flex;
    align-items: center;
  }
`;

const Layout = styled.div`
  width: ${({ width }) => width ? width : '250px'};
  border-radius: 19px;
  ${({ isOpen }) => isOpen && css`
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  `}
  > div {
    > div {
      background-color: #dedcdc;
      position: relative;
      min-width: 177px;
    }
  }
  position: relative;
  left: 82%;
  bottom: 30px;
  

  ${({ isCompanyDashboard }) => isCompanyDashboard && css`
    float: left;
    > div {
      > div {
        float: left;
        width: 100%;
      }
    }
  `}
`;

const DropdownHeading = styled.div`
  width:100%;
  display: flex;
  padding: 15px;
  border-bottom: 1px solid #b3cfdb;

  >div:first-child{
    width: 75%;
      text-transform: capitalize;
      font-family: Rubik-Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #0D4270;
     }
     >div:last-child{
       width: 25%;
       display: flex;
       justify-content: flex-end;
       >img{
         width: auto;
         height: 20px;
         cursor: pointer;
       }
     }


`;

const MenuItem = styled.li`
  line-height: 24px;
  cursor: pointer;
  position: relative;
  padding: ${({padding}) => padding ? '10px 15px' : '8px 0px'};
  font-size: 16px;
  line-height:20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({color}) => color ? 'red' : '#0D4270'};
  border-radius: 4px;
  border-bottom: ${({border}) => border ? '' : '1px solid rgba(0, 92, 135, 0.10)'};
  font-family: Rubik;
  ${({ isActive }) => isActive && css`
    background-color: white;
    color: red;
    color: ${({color}) => color ? '#007AB1' : '#007AB1'};
    font-family: ${({color}) => color ? 'Rubik-Medium' : 'Rubik'};
    &:hover {
      font-family: 'Rubik-Medium';
    }
    > div {
      display: inline-block;
    }
  `}
`;

const CheckMarkContainer = styled.div`
  display: none;
  width: 10px;
  height: 10px;
  border-radius: 50%; 
  background-color:#fff;
  margin: 0 14px 0 0px;
  height: 24px;
  width: 24px;
  >img{
    height: 100%;
    width: 100%;
  }
`;

export {OndemandContainer, HeadingContainer, SeeAllButton, NewCardContainer, NewTitle, HeadingMain, EventHeading, Layout, DropdownHeading, MenuItem, CheckMarkContainer};